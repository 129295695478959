
import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`

- Song of the day (_Canción del día_): _Mykonos_ by Fleet Foxes
- La agenda de hoy:
	- Hacer actividades en secciones C y D de Unidad 7 

*******************************************************************************
## Repaso

| _Español_ | English |
| ----- | -----|
| _ropa_ | clothes/clothing |
| _camisa_ | shirt |
| _camiseta_ | T-shirt |
| _pantalón_ | pants |
| _vestido_ | dress |
| _zapatos_ | shoes |
| _calcetines_ | socks |
| _blusa_ | blouse |
| _falda_ | skirt |
| _chaqueta_ | jacket |
| _sombrero_ | hat |
| _suéter_ | sweater |
| _impermeable_ | raincoat |
| _corbata_ | tie |
| _abrigo_ | coat | 
| _gafas_ | glasses | 
| _precio_ | price |

### Pregunta
- *¿Cuánto cuesta ____ ?* = How much is _____ ?/How much does ____ cost?
- *¿Cuánto cuestan ____ ?* = How much are ____ ?/How much do ____ cost?

### Respuesta
- *____ cuesta &lt;el precio>* = ______ costs &lt;the price> / _____ is &lt;the price>
- *____ cuestan &lt;el precio>* = ______ cost &lt;the price>/ ______ are &lt;the price>

### Ejemplos

#### Singular
- _¿Cuánto cuesta la falda?_ = How much is the skirt?/How much does the skirt cost?
- _La falda cuesta 15 dólares_ = The skirt costs fifteen dollars/The skirt is fifteen dollars

#### Plural
- _¿Cuánto cuestan los zapatos?_ =  How much are the shoes?/How much do the shoes cost?
- _Los zapatos cuestan 60 dólares_ = The shoes cost sixty dollars/The shoes are sixty dollars

*******************************************************************************
## Colors

|_Color_| Color|
|---| ---|
|_blanco_ | white|
|_azul_ | blue|
|_amarillo_ | yellow|
|_negro_ | black|
|_anaranjado_ | orange|
|_verde_ | green|
|_morado_ | purple|
|_rojo_ | red|
|_marrón_ | brown|
|_gris_ | gray|
|_rosado_ | pink|

- blusa rosada = pink blouse
- zapatos blancos = white shoes
*******************************************************************************
| Demonstrative pronoun | _Pronombre demostrativo_|
|------|------|
|this | _esto/esta/este_|
|that | _eso/ese/esa_|
|these | _estos/estas_|
|those | _esos/esas_|

- _este vestido_ = this dress
- _esos calcetines_ = those socks
- _estas faldas_ = these skirts
- _esa blusa_ = that blouse
- _Este vestido rojo cuesta $50_ = This red dress costs fifty dollars/This red dress is fifty dollars
- _¿Cuánto cuesta este vestido rojo?_ = How much is this red dress?/How much does this red dress cost?
- _Estos calcetines negros cuestan $12_ = These black socks cost twelve dollars/These black socks are twelve dollars
- _¿Cuánto cuestan estos calcetines negros?_ = How much are these black socks?/How much do these black socks cost?
- _Esos pantalones cuestan $20_ = Those pants cost twenty dollars/Those pants are twenty dollars
- _Cuánto cuestan esos pantalones?_ = How much are those pants?/How much do those pants cost?
- _Esa chaqueta cuesta $40_ = That jacket costs forty dollars/That jacket is forty dollars
- _¿Cuánto cuesta esa chaqueta?_ = How much is that jacket?/How much does that jacket cost?
`

export default function Week02() {
	return (
		<div>
			<h1>Week 9 Lecture Notes (4/3)</h1>

			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			{/* <h2>Recursos</h2> */}
			{/* <p> */}
			{/* 	<ul> */}
			{/* 		<li><a href="https://quizlet.com/363046894/la-ropa-ingles-flash-cards/">Quizlet sobre la ropa</a></li> */}
			{/* 	</ul> */}
			{/* </p> */}
		</div>
	)
}
