import React from 'react';

export default function Footer () {
	return (
		<footer>
			<div style={{padding: "0px 15px"}}>
    			&copy; {new Date().getFullYear()} Krithika Ravishankar. All rights reserved.
				<br/>
				Built with React.js and lots of love!
				Last updated on April 3, 2025.
      		</div>
    	</footer>
	);
}
