
import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`

- Song of the day (_Canción del día_): _All Too Well_ by Taylor Swift
- La agenda de hoy:
	- Repasar la Unidad 7

*******************************************************************************
## Repaso

- _¿Cuánto cuestan los calcetines negros?_ = How much are the black socks?
- _Cuestan $10._ = They cost ten dollars./They are ten dollars./They're ten dollars.
- _¿Cuánto cuesta este vestido azul?_ = How much is this blue dress?
- _Cuesta $35._ = It costs thirty-five dollars./It is thirty-five dollars/It's thirty-five dollars
- _¿Y el rojo?_ = And the red one?
- _Cuesta $45._ = It costs forty-five dollars.
- _¿Cuál prefieres?_ = Which one do you prefer?
- _¿Cuál de estos vestidos prefieres?_ = Which of these dresses do you prefer?
- _Prefiero el azul_ = I prefer the blue one
- _Prefiero el vestido azul_ = I prefer the blue dress
- _Me gustan los dos_ = I like both of them
- _¿Te gusta esta corbata?_ = Do you like this tie?
	- Yes, I do/No, I don't
- _caro_ = expensive
- _barato_ = cheap
- _demasiado_ = too
- _Es demasiado caro_ = It's too expensive
- put on = _ponerse_
	- I put on my shoes
	- Put on your jacket!
	- Put on your shoes!
- wear = _llevar_
	- I am wearing a blue shirt = _Estoy llevando una camisa azul_
- _¿Tienes una blusa rosada?_ = Do you have a pink blouse?
- _La blusa rosada es demasiado cara_ = The pink blouse is too expensive
- _Quiero devolver la blusa_ = I want to return the blouse


| _Español_ | English |
| ----- | -----|
| _ropa_ | clothes/clothing |
| _camisa_ | shirt |
| _camiseta_ | T-shirt |
| _pantalón_ | pants |
| _vestido_ | dress |
| _zapatos_ | shoes |
| _calcetines_ | socks |
| _blusa_ | blouse |
| _falda_ | skirt |
| _chaqueta_ | jacket |
| _sombrero_ | hat |
| _suéter_ | sweater |
| _impermeable_ | raincoat |
| _corbata_ | tie |
| _abrigo_ | coat | 
| _gafas_ | glasses | 
| _precio_ | price |

| Demonstrative pronoun | _Pronombre demostrativo_|
|------|------|
|this | _esto/esta/este_|
|that | _eso/ese/esa_|
|these | _estos/estas_|
|those | _esos/esas_|
*******************************************************************************
## Unidad 8

| Profesión | Profession |
| ---- | -----|
| _vendedor_ | salesperson |
| _mecánico_ | mechanic |
| _mesero_ | server |
| _recepcionista_ | receptionist |
| _cajero_ | cashier | 
| _plomero_ | plumber |
| _conductor de autobús_ | bus driver |
| _pintor_ | painter |
| _ama de casa_ | homemaker |
| _ayudante del profesor_ | teacher's aide |
| _conserje_ | custodian |

| Sentence | _Traducción_ | Profession |
| ---- | ---- | ---- |
| She answers the phone | _Ella contesta el teléfono_ | receptionist|
| She counts money | _Ella cuenta dinero_ | cashier |
| He fixes cars | _Él arregla carros_ | mechanic |
| He cleans buildings | _Él limpia edificios_ | custodian |
| She sells clothes | _Ella vende ropa_ | salesperson |
| He serves food | _Él sirve comida_ | server |

- What do you do (for work)? = _¿Qué haces?_
- What does she/he do? = _¿Qué hace ella/él?_

| Verb | _Verbo_ |
| ----- | ----- |
| answer | _contestar_ | 
| count | _contar_ |
| fix | _reparar_ |
| clean | _limpiar_ |
| sell | _vender_ |
| serve | _servir_ |

- Ellos sirven comida = They serve food
- Ellos reparan carros = They fix cars
- Yo cuento dinero = I count money
- Ella cuenta dinero = She counts money
- Ella está contando dinero = She is counting money
- Yo limpio edificios = I clean buildings

Extra
- warehouse - almacén
- I work in ___ = Trabajo en ___
	- una oficina = an office (ofis)
	- una esuela = a school
	- una cocina = a kitchen
- My job is taking care of children/kids = Mi trabajo es cuidar a los niños
- Está al final del pasillo = It's at the end of the aisle/hallway
*******************************************************************************

`

export default function Week02() {
	return (
		<div>
			<h1>Week 10 Lecture Notes (4/10)</h1>

			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			<h2>Recursos</h2>
			<p>
				<ul>
					<li><a href="https://quizlet.com/363046894/la-ropa-ingles-flash-cards/">Quizlet sobre la ropa</a></li>
					<li><a href="https://ingles.com">ingles.com</a></li>
				</ul>
			</p>
		</div>
	)
}
