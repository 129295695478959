import React from 'react';
import { Link } from 'react-router-dom';

export default function Teaching () {
	return (
		<div>
			<h1>ESL Lecture Notes</h1>
			<p>
				I'm teaching an introductory ESL course
				at <a href="https://elbuen.org/">El Buen Samaritano</a> during the spring 2025 session!
				<br/>
				<br/>
				Here are a set of lecture notes covering both
				things we've talked about in class as well as some additional examples.
				I'll be updating this page as the semester progresses.
				Hopefully this helps!
			</p>
			<ul>
				<li><Link to="/teaching/week01">Week 1 (week of 2/3)</Link></li>
				<li><Link to="/teaching/week02">Week 2 (week of 2/10)</Link></li>
				<li><Link to="/teaching/week03">Week 3 (week of 2/17)</Link></li>
				<li><Link to="/teaching/week04">Week 4 (week of 2/24)</Link></li>
				<li><Link to="/teaching/week05">Week 5 (week of 3/3)</Link></li>
				<li>Week 6 (week of 3/10): No class, see y'all next week!</li>
				<li><Link to="/teaching/week06">Week 7 (week of 3/17)</Link></li>
				<li><Link to="/teaching/week07">Week 7 (week of 3/17)</Link></li>
				<li><Link to="/teaching/week08">Week 8 (week of 3/24)</Link></li>
				<li><Link to="/teaching/week09">Week 9 (week of 3/31)</Link></li>
				<li><Link to="/teaching/week10">Week 10 (week of 4/3)</Link></li>

			</ul>

		</div>
	)
}
